import React from 'react';
import { motion } from 'framer-motion';
import { MdArrowDownward } from 'react-icons/md';
import { Link } from 'react-scroll';
import heroImage from '../assets/hero1.jpg'; // Import the image

const Arrow = () => (
  <motion.div
    className="text-white text-4xl mt-16 mx-auto" // Reduced top margin for arrow
    initial={{ opacity: 0, y: 0 }}
    animate={{ opacity: 1, y: [0, -10, 0] }}
    transition={{ duration: 1.5, repeat: Infinity, repeatType: 'loop' }}
  >
    <MdArrowDownward />
  </motion.div>
);

const Hero = ({ heading, buttonText, scrollTarget }) => {
  return (
    <motion.section
      id="home"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
      className="bg-cover bg-center bg-no-repeat h-screen flex items-center justify-center relative"
      style={{ backgroundImage: `url(${heroImage})` }} // Use the imported image
    >
      <div className="absolute inset-0 bg-black bg-opacity-50" />
      <div className="container mx-auto max-w-screen-xl flex flex-col-reverse md:flex-row items-center justify-center relative ">
        <div className="w-full md:w-1/2 text-center md:text-center">
          {/* Reduced top margin on the heading */}
          <h1 className="text-3xl md:text-5xl font-bold text-white mb-2 mt-2">
            {heading || 'Welcome to HesPro Sports'} <span className="uppercase"></span>
          </h1>
          <Link
            to={scrollTarget || 'services'}
            smooth={true}
            duration={500}
            className="flex flex-col justify-center"
          >
            {/* Reduced top margin on the button */}
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              className="bg-custom-orange text-white px-6 py-3 rounded-full text-lg font-semibold mt-2 hover:bg-custom-orange-darker hover:text-white"
            >
              {buttonText || 'Get Started'}
            </motion.button>
            <Arrow />
          </Link>
        </div>
      </div>
    </motion.section>
  );
};

export default Hero;
