import React from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom'; // Import for category page link
import { FaRunning, FaBasketballBall, FaFootballBall, FaSwimmer, FaBicycle, FaTableTennis, FaSkiing, FaDumbbell, FaBaseballBall } from 'react-icons/fa';

const ProductCategories = () => {
  const categories = [
    {
      icon: <FaRunning />,
      title: 'Running Gear',
      description: 'High-quality apparel and accessories for runners.',
    },
    {
      icon: <FaBasketballBall />,
      title: 'Basketball Equipment',
      description: 'Everything you need for basketball, from balls to shoes.',
    },
    {
      icon: <FaFootballBall />,
      title: 'Football Gear',
      description: 'Top-notch gear for all your football needs.',
    },
    {
      icon: <FaSwimmer />,
      title: 'Swimming Equipment',
      description: 'Swimwear, goggles, and accessories for swimmers of all levels.',
    },
    {
      icon: <FaBicycle />,
      title: 'Cycling Gear',
      description: 'Bikes, helmets, and gear for cycling enthusiasts.',
    },
    {
      icon: <FaTableTennis />,
      title: 'Table Tennis',
      description: 'Paddles, balls, and tables for table tennis players.',
    },
    {
      icon: <FaSkiing />,
      title: 'Skiing & Snowboarding',
      description: 'Skis, snowboards, and gear for winter sports.',
    },
    {
      icon: <FaDumbbell />,
      title: 'Fitness Equipment',
      description: 'Dumbbells, resistance bands, and more for your home gym.',
    },
    {
      icon: <FaBaseballBall />,
      title: 'Baseball Gear',
      description: 'Bats, gloves, and apparel for baseball players.',
    },
  ];

  return (
    <section id="categories" className="py-20 bg-gray-800 text-gray-300">
      <div className="container mx-auto px-4">
        <h2 className="text-4xl font-bold text-center mb-12 text-custom-orange">Product Categories</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {categories.map((category, index) => (
            <Link key={index} to={`/category/${category.slug}`}> {/* Add category slug to URL */}
              <motion.div
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: index * 0.2 }}
                className="bg-gray-700 p-8 rounded-lg text-center shadow-lg hover:shadow-xl transition-shadow duration-300"
              >
                <div className="flex justify-center mb-6">
                  <div className="bg-custom-orange text-white p-4 rounded-full text-5xl">{category.icon}</div>
                </div>
                <h3 className="text-2xl font-semibold mb-2 text-custom-green">{category.title}</h3>
                <p className="text-lg text-gray-300">{category.description}</p>
              </motion.div>
            </Link>
          ))}
        </div>
      </div>
    </section>
  );
};

export default ProductCategories;