import React from 'react';
import { motion } from 'framer-motion';

const ContactSection = ({ heading }) => {
  return (
    <section id="contact" className="py-16 bg-gray-800 text-center">
      <div className="container mx-auto px-4">
        <h2 className="text-4xl font-bold mb-12 text-custom-orange">{heading || 'Contact Us'}</h2>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
          className="max-w-6xl mx-auto bg-gray-700 p-8 rounded-lg shadow-xl flex flex-col md:flex-row justify-between items-center"
          aria-labelledby="contact-title"
        >
          <h2 id="contact-title" className="sr-only">Contact Information</h2>
          <div className="text-center md:text-left mb-8 md:mb-0 md:w-1/3">
            <h3 className="text-2xl font-semibold text-custom-white mb-2">Email Us</h3>
            <p className="text-gray-400">hesproclub@gmail.com</p>
          </div>

          <div className="border-t border-b border-gray-600 py-6 md:border-none md:py-0 md:mx-8 md:w-1/3">
            <h3 className="text-2xl font-semibold text-custom-white mb-2">Contact Number</h3>
            <p className="text-gray-400">+91 7559344889</p>
          </div>

          <div className="text-center md:w-1/3">
            <h3 className="text-2xl font-semibold text-custom-white mb-4">Let's Talk on WhatsApp</h3>
            <a
              href="https://wa.me/917559344889"
              target="_blank"
              rel="noopener noreferrer"
              className="inline-block bg-green-500 text-white px-6 py-3 rounded-lg font-bold hover:bg-green-700 transition duration-300"
            >
              Chat with us
            </a>

          </div>
        </motion.div>

        {/* Optional: Add a contact form or social media links here */}
      </div>
    </section>
  );
};

export default ContactSection;