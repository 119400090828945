import { Route, Routes } from 'react-router-dom';
import LandingPage from './pages/LandingPage';
import Shop from './pages/Shop';
import ContactPage from './pages/Contact';
import AboutUs from './pages/AboutUs';
import TestimonialsPage from './pages/Testimonials';
import Kabaddi from './pages/Kabaddi'; 
import Sponsors from './pages/Sponsors';
import Service from './pages/Service';
import TermsAndConditions from './pages/TermsAndConditions.jsx';
import PolicyPages from './pages/PolicyPages.jsx';

function App() {
  return (
    <Routes>
      <Route path="/" element={<LandingPage />} />
      <Route path="/home" element={<LandingPage />} />
      <Route path="/shop" element={<Shop />} />
      <Route path="/contact" element={<ContactPage />} />
      <Route path="/about" element={<AboutUs />} />
      <Route path="/testimonials" element={<TestimonialsPage />} />
      <Route path="/sponsors" element={<Sponsors />} />
      <Route path="/service" element={<Service />} />
      <Route path="/sports/kabaddi" element={<Kabaddi />} /> 
      <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
      <Route path="/privacy-policy" element={<PolicyPages />} />
    </Routes>
  );
}

export default App;
