import React from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';

const FeaturedProducts = () => {
  const products = [
    {
      image: '',
      title: 'Premium Running Shoes',
      price: '$120.00',
    },
    {
      image: '',
      title: 'Professional Basketball',
      price: '$50.00',
    },
    {
      image: '',
      title: 'Football Jersey',
      price: '$75.00',
    },
    {
      image: '',
      title: 'Yoga Mat',
      price: '$25.00',
    },
    {
      image: '',
      title: 'Boxing Gloves',
      price: '$45.00',
    },
    {
      image: '',
      title: 'Tennis Racket',
      price: '$85.00',
    },
    {
      image: '',
      title: 'Cycling Helmet',
      price: '$60.00',
    },
    {
      image: '',
      title: 'Soccer Ball',
      price: '$30.00',
    },
    {
      image: '',
      title: 'Swimming Goggles',
      price: '$20.00',
    },
    {
      image: '',
      title: 'Baseball Bat',
      price: '$70.00',
    },
    {
      image: '',
      title: 'Golf Clubs Set',
      price: '$300.00',
    },
    {
      image: '',
      title: 'Hiking Boots',
      price: '$110.00',
    },
    {
      image: '',
      title: 'Basketball Shoes',
      price: '$130.00',
    },
    {
      image: '',
      title: 'Cricket Bat',
      price: '$150.00',
    },
    {
      image: '',
      title: 'Running Shorts',
      price: '$40.00',
    },
  ];

  const handleImageError = (e) => {
    e.target.src = 'https://placehold.co/600x400?text=Buy+Now'; // Replace with your placeholder image path
  };

  return (
    <section id="featured-products" className="py-16 bg-gray-800 text-gray-300">
      <div className="container mx-auto px-4">
        <h2 className="text-4xl font-bold text-center mb-12 text-custom-orange">Featured Products</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {products.map((product, index) => (
            <Link key={index} to={`/product/${product.id}`}>  {/* Add product ID to URL */}
              <motion.div
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: index * 0.2 }}
                className="bg-gray-700 p-8 rounded-lg text-center shadow-lg hover:shadow-xl transition-shadow duration-300"
              >
                <img
                  src={product.image || '/path/to/placeholder-image.jpg'}
                  alt={product.title}
                  className="mb-6 w-full h-48 object-cover rounded-lg"
                  onError={handleImageError}
                />
                <h3 className="text-2xl font-semibold mb-2 text-custom-green">{product.title}</h3>
                <p className="text-lg font-bold text-custom-orange">{product.price}</p>
              </motion.div>
            </Link>
          ))}
        </div>
      </div>
    </section>
  );
};

export default FeaturedProducts;
