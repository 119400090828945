import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { FaHome, FaEnvelope, FaBars, FaTimes } from 'react-icons/fa';
import { FaShop } from "react-icons/fa6";
import { VscFeedback } from "react-icons/vsc";
import { BsFillPersonVcardFill } from "react-icons/bs";
import { SiGithubsponsors } from "react-icons/si";
import { MdSportsKabaddi } from 'react-icons/md';
import { FaServicestack } from 'react-icons/fa';


import { Link } from 'react-router-dom';
import logo from '../assets/logo.svg';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => setIsOpen(!isOpen);

  return (
    <motion.header
      initial={{ opacity: 0, y: -50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      className="bg-gray-900 text-white py-4 px-6 fixed w-full z-10 shadow-lg"
    >
      <nav className="flex items-center justify-between container mx-auto">
        <img src={logo} alt="Hespro Sports Logo" className="h-12" />
        <button onClick={toggleMenu} className="lg:hidden text-white text-2xl z-50" aria-label="Toggle navigation">
          {isOpen ? <FaTimes /> : <FaBars />}
        </button>
        <ul
          className={`lg:flex pl-5 lg:space-x-6 absolute lg:static top-16 right-0 w-full lg:w-auto bg-black lg:bg-transparent transition-transform duration-300 ease-in-out ${isOpen ? 'translate-x-0' : 'translate-x-full'} lg:translate-x-0`}
        >
          <li className="lg:hidden pt-5 text-center mb-4">
            <Link to="/home" className="flex items-center hover:text-custom-orange" onClick={() => setIsOpen(false)}>
              <FaHome className="mr-2" /> Home
            </Link>
          </li>
          <li className="lg:hidden text-center mb-4">
            <Link to="/shop" className="flex items-center hover:text-custom-orange" onClick={() => setIsOpen(false)}>
              <FaShop className="mr-2" /> Shop
            </Link>
          </li>
          <li className="lg:hidden text-center mb-4">
            <Link to="/about" className="flex items-center hover:text-custom-orange" onClick={() => setIsOpen(false)}>
              <BsFillPersonVcardFill className="mr-2" /> About Us
            </Link>
          </li>
          <li className="lg:hidden text-center mb-4">
            <Link to="/sports/kabaddi" className="flex items-center hover:text-custom-orange" onClick={() => setIsOpen(false)}>
              <MdSportsKabaddi className="mr-2" /> Kabaddi
            </Link>
          </li>
          <li className="lg:hidden text-center mb-4">
            <Link to="/service" className="flex items-center hover:text-custom-orange" onClick={() => setIsOpen(false)}>
              <FaServicestack className="mr-2" /> Services
            </Link>
          </li>
          <li className="lg:hidden text-center mb-4">
            <Link to="/testimonials" className="flex items-center hover:text-custom-orange" onClick={() => setIsOpen(false)}>
              <VscFeedback className="mr-2" /> Testimonials
            </Link>
          </li>
          <li className="lg:hidden text-center mb-4">
            <Link to="/sponsors" className="flex items-center hover:text-custom-orange" onClick={() => setIsOpen(false)}>
              <SiGithubsponsors className="mr-2" /> Sponsors
            </Link>
          </li>
          <li className="lg:hidden text-center mb-4">
            <Link to="/contact" className="flex items-center hover:text-custom-orange" onClick={() => setIsOpen(false)}>
              <FaEnvelope className="mr-2" /> Contact
            </Link>
          </li>
          <li className="hidden lg:flex items-center">
            <Link to="/home" className="flex items-center hover:text-custom-orange">
              <FaHome className="mr-2" /> Home
            </Link>
          </li>
          <li className="hidden lg:flex items-center">
            <Link to="/shop" className="flex items-center hover:text-custom-orange">
              <FaShop className="mr-2" /> Shop
            </Link>
          </li>
          <li className="hidden lg:flex items-center">
            <Link to="/about" className="flex items-center hover:text-custom-orange">
              <BsFillPersonVcardFill className="mr-2" /> About Us
            </Link>
          </li>
          <li className="hidden lg:flex items-center">
            <Link to="/sports/kabaddi" className="flex items-center hover:text-custom-orange">
              <MdSportsKabaddi className="mr-2" /> Kabaddi
            </Link>
          </li>
          <li className="hidden lg:flex items-center">
            <Link to="/service" className="flex items-center hover:text-custom-orange">
              <FaServicestack className="mr-2" /> Services
            </Link>
          </li>
          <li className="hidden lg:flex items-center">
            <Link to="/testimonials" className="flex items-center hover:text-custom-orange">
              <VscFeedback className="mr-2" /> Testimonials
            </Link>
          </li>
          <li className="hidden lg:flex items-center">
            <Link to="/sponsors" className="flex items-center hover:text-custom-orange">
              <SiGithubsponsors className="mr-2" /> Sponsors
            </Link>
          </li>
          <li className="hidden lg:flex items-center">
            <Link to="/contact" className="flex items-center hover:text-custom-orange">
              <FaEnvelope className="mr-2" /> Contact
            </Link>
          </li>
        </ul>
      </nav>
    </motion.header>
  );
};

export default Navbar;