import React from "react";
import { motion } from "framer-motion";
import InfiniteScroll from "react-infinite-scroll-component";
import "../assets/css/testimonials.css";
import Navbar from "../components/Header";
import Footer from "../components/Footer";

const testimonials = [
  {
    name: "Aarav Patel",
    position: "Parent",
    image: "https://via.placeholder.com/100",
    text: "The sports academy has been incredible for my child's growth. The coaches are dedicated and the training programs are well-structured.",
  },
  {
    name: "Isha Sharma",
    position: "Parent",
    image: "https://via.placeholder.com/100",
    text: "My daughter has improved immensely thanks to the academy. The coaches provide personalized attention and encouragement.",
  },
  {
    name: "Ravi Kumar",
    position: "Parent",
    image: "https://via.placeholder.com/100",
    text: "The sports academy offers an excellent balance of skill development and fun. My son eagerly looks forward to every training session.",
  },
  {
    name: "Priya Singh",
    position: "Parent",
    image: "https://via.placeholder.com/100",
    text: "Fantastic coaching staff and facilities. My child's confidence and performance have significantly improved.",
  },
  {
    name: "Vikram Reddy",
    position: "Parent",
    image: "https://via.placeholder.com/100",
    text: "The academy provides a supportive environment that has helped my child excel in sports while having fun.",
  },
  {
    name: "Neha Gupta",
    position: "Parent",
    image: "https://via.placeholder.com/100",
    text: "Impressive facilities and skilled coaches. The academy has been a great choice for developing my child's athletic skills.",
  },
  {
    name: "Arjun Verma",
    position: "Parent",
    image: "https://via.placeholder.com/100",
    text: "My child's passion for sports has grown thanks to the academy's expert coaching and well-rounded programs.",
  },
  {
    name: "Ananya Joshi",
    position: "Parent",
    image: "https://via.placeholder.com/100",
    text: "The academy's focus on both skill development and teamwork is exceptional. My child has made great progress since joining.",
  },
  {
    name: "Karan Mehta",
    position: "Parent",
    image: "https://via.placeholder.com/100",
    text: "From the start, the academy has exceeded our expectations. The coaches are knowledgeable and provide excellent support.",
  },
  {
    name: "Sneha Agarwal",
    position: "Parent",
    image: "https://via.placeholder.com/100",
    text: "The sports academy has been a fantastic experience for my child. The balance of fun and learning is perfectly managed.",
  },
  {
    name: "Rajesh Nair",
    position: "Parent",
    image: "https://via.placeholder.com/100",
    text: "The academy's commitment to skill-building and personal growth is evident. My child has improved significantly and enjoys every session.",
  },
  {
    name: "Sanya Kapoor",
    position: "Parent",
    image: "https://via.placeholder.com/100",
    text: "A great environment for young athletes to develop their skills. My child's confidence and enthusiasm for sports have grown immensely.",
  },
  {
    name: "Siddharth Choudhury",
    position: "Parent",
    image: "https://via.placeholder.com/100",
    text: "The coaching staff at the academy is top-notch. My child's athletic abilities have advanced considerably since joining.",
  },
  {
    name: "Divya Rao",
    position: "Parent",
    image: "https://via.placeholder.com/100",
    text: "The academy's focus on both physical and mental development is impressive. My child's skills and confidence have greatly improved.",
  },
  {
    name: "Amit Singh",
    position: "Parent",
    image: "https://via.placeholder.com/100",
    text: "Excellent training programs and dedicated coaches. The academy has made a significant difference in my child's athletic journey.",
  },
  {
    name: "Meera Desai",
    position: "Parent",
    image: "https://via.placeholder.com/100",
    text: "The academy provides a wonderful balance of learning and enjoyment. My child is thriving and looking forward to each practice.",
  },
  {
    name: "Rohit Patel",
    position: "Parent",
    image: "https://via.placeholder.com/100",
    text: "Fantastic coaching and facilities. My child has developed a strong passion for sports thanks to the academy's supportive environment.",
  },
  {
    name: "Tanya Kapoor",
    position: "Parent",
    image: "https://via.placeholder.com/100",
    text: "The academy has been instrumental in my child's growth. The quality of coaching and facilities is exceptional.",
  },
  {
    name: "Vikrant Sharma",
    position: "Parent",
    image: "https://via.placeholder.com/100",
    text: "My child's skills and enthusiasm for sports have grown significantly. The academy's approach to coaching is highly effective.",
  },
  {
    name: "Nikita Jain",
    position: "Parent",
    image: "https://via.placeholder.com/100",
    text: "The academy's commitment to excellence is evident in every session. My child has gained confidence and improved their skills remarkably.",
  },
  {
    name: "Aditya Sinha",
    position: "Parent",
    image: "https://via.placeholder.com/100",
    text: "A well-structured program with expert coaches. The academy has been a great investment in my child's athletic development.",
  },
  
];


const TestimonialCard = ({ testimonial }) => {
  return (
    <motion.div
      className="flex-shrink-0 w-80 bg-gray-800 p-4 rounded-lg shadow-lg mx-2"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <div className="flex items-center mb-2">
        <img
          src={testimonial.image}
          alt={testimonial.name}
          className="w-12 h-12 rounded-full border-4 border-orange-500 mr-2"
        />
        <div>
          <h4 className="text-lg font-semibold text-white">
            {testimonial.name}
          </h4>
          <p className="text-gray-400 text-sm">{testimonial.position}</p>
        </div>
      </div>
      <p className="text-gray-400 text-xs">{testimonial.text}</p>
    </motion.div>
  );
};

const TestimonialsPage = () => {
  const fetchMoreData = () => {
    // Fetch more data logic here if needed
  };

  const chunkArray = (array, chunkSize) => {
    const result = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      result.push(array.slice(i, i + chunkSize));
    }
    return result;
  };

  const rows = chunkArray(testimonials, 7); // Dividing testimonials into chunks of 7

  return (
    <>
      <Navbar />
      <div className="font-sans bg-gray-900 text-gray-300 pt-20 pb-8 overflow-hidden">
        {/* Adjusted padding-top to ensure space for Navbar */}
        <h2 className="text-3xl font-bold text-white text-center my-12">
          What Say Our Parents!
        </h2>
        <div className="w-full max-w-screen-xl mx-auto">
          {rows.map((row, rowIndex) => (
            <div
              key={rowIndex}
              className={`relative overflow-hidden mb-12 ${
                rowIndex % 2 === 0 ? "scroll-left" : "scroll-right"
              }`}
            >
              <div className="flex flex-nowrap animate-scroll">
                {row.map((testimonial, index) => (
                  <TestimonialCard key={index} testimonial={testimonial} />
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default TestimonialsPage;