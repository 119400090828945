import React from 'react';
import { motion } from 'framer-motion';
import { FaStar, FaUsers, FaFemale, FaCogs, FaGraduationCap, FaHeartbeat } from 'react-icons/fa';

const Overview = () => {
  const overviewPoints = [
    {
      icon: <FaStar />,
      title: 'Comprehensive Services',
      description: 'Wide range of sports training, learning, and related services.',
      ariaLabel: 'Comprehensive sports services'
    },
    {
      icon: <FaUsers />,
      title: 'Inclusive Training',
      description: 'Sports training for everyone, from beginners to national-level athletes.',
      ariaLabel: 'Inclusive sports training'
    },
    {
      icon: <FaCogs />,
      title: 'Complete Support',
      description: 'Total support for sports development, catering to all skill levels.',
      ariaLabel: 'Complete sports development support'
    },
    {
      icon: <FaFemale />,
      title: 'Focus on Women',
      description: 'Emphasis on development and support of girls and women in sports.',
      ariaLabel: 'Focus on women in sports'
    },
    {
      icon: <FaHeartbeat />,
      title: 'Event & Academies',
      description: 'Excelling in sports event management and running successful academies.',
      ariaLabel: 'Sports event management and academies'
    },
    {
      icon: <FaGraduationCap />,
      title: 'Holistic Development',
      description: 'Support for players & coaches, including education, training, job assistance, and financing.',
      ariaLabel: 'Holistic player & coach development'
    },
    {
      icon: <FaUsers />,
      title: 'Youth Empowerment',
      description: 'Committed to raising awareness and providing opportunities for young athletes.',
      ariaLabel: 'Youth athlete empowerment'
    },
  ];

  return (
    <section id="overview" className="py-20 bg-gray-900 text-custom-white">
      <div className="container mx-auto px-4">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-12">
          {overviewPoints.map((point, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, scale: 0.9 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.5, delay: index * 0.2 }}
              className="bg-gray-800 p-10 rounded-lg flex items-start shadow-lg hover:shadow-xl transition-shadow duration-300"
            >
              <div className="mr-6 flex-shrink-0">
                <div className="bg-custom-orange text-white p-5 rounded-full text-4xl">
                  {point.icon}
                </div>
              </div>
              <div>
                <h3 className="text-custom-white text-2xl font-semibold mb-2">{point.title}</h3>
                <p className="text-lg">{point.description}</p>
                <span role="img" aria-label={point.ariaLabel} className="hidden"></span>
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Overview;