// ShopPage.jsx
import React from 'react';
import Header from '../components/Header';
import Hero from '../components/Hero';
import ProductCategories from '../components/shopPage/ProductCategories';
import FeaturedProducts from '../components/shopPage/FeaturedProducts';
import ContactForm from '../components/ContactForm';
import Footer from '../components/Footer';

function ShopPage() {
  return (
    <div className="font-sans bg-[#D8DBD5]">
      <Header />
      <Hero heading="Welcome to Our Sports" subheading="Find the best sports gear at unbeatable prices!" buttonText="Shop Now" scrollTarget="ProductCategories" />
      <div id='ProductCategories' >
        <ProductCategories />
      </div>
      <FeaturedProducts />
      <ContactForm heading={'Buy Now Through Following Channel'}/>
      <Footer />
    </div>
  );
}

export default ShopPage;
