import React from 'react';
import { motion } from 'framer-motion';
import Header from '../components/Header';
import Footer from '../components/Footer';


const sponsors = [
    //{ logo: 'https://via.placeholder.com/150', name: 'Company Name' },
];


const Sponsors = () => {
  return (
    <>
      <Header />
      <section className="bg-gray-900 text-gray-300 py-16" style={{ minHeight: 'calc(100vh - 40px - 80px)' }}>
        {/* Adjust the 64px (header) and 96px (footer) values if needed */}
        <div className="container mx-auto px-4 py-10">
          <h2 className="text-3xl font-bold text-white text-center mb-12">Our Sponsors</h2>

          {sponsors.length > 0 ? (
            <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5 gap-8">
              {sponsors.map((sponsor, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, scale: 0.9 }}
                  animate={{ opacity: 1, scale: 1 }}
                  transition={{ duration: 0.5, delay: index * 0.2 }}
                  className="bg-gray-800 p-8 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300"
                >
                  <img
                    src={sponsor.logo}
                    alt={sponsor.name}
                    className="w-full h-24 object-contain"
                  />
                </motion.div>
              ))}
            </div>
          ) : (
            <p className="text-center text-gray-400">Currently no sponsors.</p>
          )}
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Sponsors;
